/** @jsx jsx */
import { jsx } from "@emotion/core";
import Icon from "../../../common/components/UI/Icon";
import { colors } from "../../../common/styles/Colors";
import { unstable_useMediaQuery as useMediaQuery } from "@material-ui/core/useMediaQuery";

const styles = {
    root: {
        padding: "9px 0px",
        borderBottom: "1px solid #EAEAEA",
        background: "#FAFAFA",
        "@media (max-width: 1023px)": {
            padding: "12px 8px",
            "&:first-of-type": {
                padding: "22px 8px 12px",
            },
        },
    },
    rootMobile: {
        width: "100%",
        display: "block",
        border: "0 none",
        outline: "0 none",
        margin: 0,
        padding: "12px 8px",
        borderBottom: "1px solid #EAEAEA",
        background: "#FAFAFA",
        "&:first-of-type": {
            padding: "22px 8px 12px",
        },
    },
    container: {
        margin: "0 auto",
        padding: "0 8px",
        maxWidth: 566,
        display: "flex",
        // alignItems: "center",
    },
    icon: {
        marginRight: 14,
        flexShrink: 0,
    },
    text: {
        fontSize: 14,
        lineHeight: 1.3,
        color: "rgba(0,0,0,0.87)",
        flex: 1,
        textAlign: "left",
    },
    separator: {
        margin: "0 3px",
    },
    link: {
        padding: 0,
        margin: 0,
        background: "transparent",
        border: 0,
        outline: 0,
        fontSize: 14,
        color: "#FF2D55",
        textDecoration: "underline",
    },
};

export const NotificationItem = ({ title, onClick }) => {
    const isDesktop = useMediaQuery("(min-width:960px)");

    return isDesktop ? (
        <div css={styles.root}>
            <div css={styles.container}>
                <Icon
                    css={styles.icon}
                    name="banner"
                    width={18}
                    height={18}
                    viewBox="0 0 18 18"
                    fill={colors.grey2}
                />
                <p css={styles.text}>
                    {title} <span css={styles.separator}>-</span>{" "}
                    <button css={styles.link} onClick={onClick}>
                        read more
                    </button>
                </p>
            </div>
        </div>
    ) : (
        <button css={styles.rootMobile} onClick={onClick}>
            <div css={styles.container}>
                <Icon
                    css={styles.icon}
                    name="banner"
                    width={18}
                    height={18}
                    viewBox="0 0 18 18"
                    fill="#FF2D55"
                />
                <p css={styles.text}>{title}</p>
            </div>
        </button>
    );
};
