import { Component } from "react";
import { connect } from "react-redux";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import actionCreators from "../../../store/Calendar/FullList/actionCreators";
import DayItem from "../components/DayItem";
import { Container } from "../../../common/components/UI/Container";
import ButtonFloating from "../../../common/components/UI/ButtonFloating";
import { calendarPage } from "../CalendarStyles";
import CalendarBar from "../CalendarBar";
import HeaderCalendarMobile from "../../../common/components/Header/HeaderCalendarMobile";
import NoResults from "../../../common/components/NoResults/";
import moment from "moment";
import eventActionCreator from "../../../store/Calendar/Event/actionCreators";
import calendarBoardActionCreator from "../../../store/Calendar/CalendarBoard/actionCreators";
import CalendarPlaceholder from "../../../common/components/Skeletons/CalendarPlaceholder";
import GTM from "../../../common/services/GTM";
import { BannerNotifications } from "../Notifications";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { FormControl, InputLabel } from "@material-ui/core";

class FullListContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            calendarType: "all",
        };
    }
    componentDidMount() {
        const {
            clearEventsList,
            getEventsList,
            setOrigin,
            resetBoard,
            clearEvent,
            setDefaultStatus,
        } = this.props;
        const { calendarType } = this.state;

        setDefaultStatus(true);
        clearEventsList();
        resetBoard();
        getEventsList({
            calendarType,
            start: `${moment().format("DD")}.${moment().format("MM")}.${moment().format("YYYY")}`,
        });
        clearEvent();
        setOrigin("list");

        GTM.dispatch({
            event: "pageView",
            pageUrl: `/calendar/list/${calendarType}`,
        });

        localStorage.setItem("calendarLast", "list");
    }

    render() {
        const { data, currentMonth } = this.props;
        const { calendarType } = this.state;

        let prepared = data;

        if (prepared && calendarType !== "all") {
            // filter
            prepared = prepared
                .map(m => {
                    return {
                        ...m,
                        days: m.days.map(d => {
                            return {
                                ...d,
                                events: d.events.filter(e => e.calendarType === calendarType),
                            };
                        }),
                    };
                })
                .map(m => {
                    return {
                        ...m,
                        days: m.days.filter(d => d.events.length > 0),
                    };
                })
                .filter(m => m.days.length > 0);
        }

        return (
            <div css={calendarPage.body}>
                <HeaderCalendarMobile {...{ currentMonth }} />
                <CalendarBar />
                <BannerNotifications />
                <Container width={592}>
                    <CalendarPlaceholder isLoaded={Boolean(data)}>
                        <div css={calendarPage.inner}>
                            <div css={calendarPage.calendarType}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="calendar-type">Show</InputLabel>
                                    <Select
                                        inputProps={{
                                            id: "calendar-type",
                                        }}
                                        value={this.state.calendarType}
                                        onChange={val => {
                                            this.setState({ calendarType: val.target.value });
                                        }}
                                    >
                                        <MenuItem value={"all"}>All calendars</MenuItem>
                                        <MenuItem value={"timetables"}>Timetables & exams</MenuItem>
                                        <MenuItem value={"my-events"}>My events</MenuItem>
                                        <MenuItem value={"appointments"}>Appointments</MenuItem>
                                        <MenuItem value={"key-academic-dates"}>
                                            Moodle dates
                                        </MenuItem>
                                        <MenuItem value={"lse-events"}>LSE events</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>

                            <div css={calendarPage.fullList}>
                                {prepared &&
                                    (prepared.length > 0 ? (
                                        prepared.map((item, i) => <DayItem key={i} {...{ item }} />)
                                    ) : (
                                        <div css={calendarPage.noResults}>
                                            <NoResults
                                                heading="No events found!"
                                                message="Your calendar is empty ..."
                                            />
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </CalendarPlaceholder>
                </Container>
                <ButtonFloating to="/calendar/event/new" icon="add" />
            </div>
        );
    }
}

const mapStateToProps = ({
    calendar: {
        fullList: { data },
        board: { currentMonth, currentYear },
    },
    MainView: { isLoading },
}) => ({
    data,
    currentMonth,
    currentYear,
    isLoading,
});

const mapDispatchToProps = {
    getEventsList: actionCreators.getEventsList.create,
    clearEvent: eventActionCreator.clearEvent.create,
    clearEventsList: actionCreators.clearEventsList.create,
    setOrigin: eventActionCreator.setOrigin.create,
    resetBoard: calendarBoardActionCreator.resetBoard.create,
    setDefaultStatus: actionCreators.setDefaultStatus.create,
};

export default connect(mapStateToProps, mapDispatchToProps)(FullListContainer);
